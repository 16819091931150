export enum variables {
    AZURE_APP_INSIGHTS_KEY = 'REACT_APP_AZURE_APP_INSIGHTS_KEY',
    DASH_EROS_UPLOADER_QUERY_PARSE_OUTCOME_RETRY_COUNT = 'REACT_APP_DASH_EROS_UPLOADER_QUERY_PARSE_OUTCOME_RETRY_COUNT',
    DASH_EROS_UPLOADER_QUERY_LOAD_OUTCOME_RETRY_COUNT = 'REACT_APP_DASH_EROS_UPLOADER_QUERY_LOAD_OUTCOME_RETRY_COUNT',
    BROWSER_SUPPORT = 'REACT_APP_BROWSER_SUPPORT',
    DEVICE_ID = 'REACT_APP_DEVICE_IDENTIFIER',
    INSULET_ID_APP_NAME = 'REACT_APP_INSULET_ID_APP_NAME',
    OKTA_CLIENT_ID = 'REACT_APP_OKTA_CLIENT_ID',
    OKTA_ISSUER = 'REACT_APP_OKTA_ISSUER',
    VERSION = 'REACT_APP_VERSION',
    PAUSE_IT_FEATURE_TOGGLE = 'REACT_APP_PAUSE_IT_FEATURE_TOGGLE',
    PUBLIC_URL = 'PUBLIC_URL',
    PWD_APP_URL = 'REACT_APP_PWD_APP_URL',
    HCP_APP_URL = 'REACT_APP_HCP_APP_URL',
    PATIENT_INVITATION_EXPIRATION_DAYS = 'REACT_APP_PATIENT_INVITATION_EXPIRATION_DAYS',
    OMNIPOD_CONNECT_HOME_URL = 'REACT_APP_OMNIPOD_CONNECT_HOME_URL',
    OMNIPOD_CONNECT_TRAINING_URL = 'REACT_APP_OMNIPOD_CONNECT_TRAINING_URL',
    CLINICAL_WORKER_INVITATION_EXPIRATION_DAYS = 'REACT_APP_CLINICAL_WORKER_INVITATION_EXPIRATION_DAYS',
    PENDING_PATIENTS_AND_CLINICAL_WORKERS_COUNTS_TIMER_INTERVAL_MILLISECONDS = 'REACT_APP_PENDING_PATIENTS_AND_CLINICAL_WORKERS_COUNTS_TIMER_INTERVAL_MILLISECONDS',
}
