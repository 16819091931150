import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import printJS from 'print-js';

import { getStateAfterAction, uploadFile } from 'src/web-services/Upload.service';
import { getPatientData } from 'src/helpers/services/Patients';
import { StatusCode } from 'src/models/consts/statusCodes';
import { ErrorPages, IDetailedPatients, RoutePath, IPatientsTableRows } from 'src/models/table.models';
import { saveUploadedFileData, getUploadMetadata } from 'src/helpers/services/SaveUploadData';
import { sendAzureEvent } from 'src/helpers/appInsights';
import { analyticsEventNames } from 'src/models/consts/analytics';
import { createHcpPublicPagesPath } from 'src/helpers/createPath';
import { UploadedFileMetadata } from 'src/models/uploader.models';
import { isPauseItEnabled } from 'src/helpers/featureToggles';
import { SELECTED_PATIENT_ID_PARAM_NAME } from 'src/models/consts/patientDirectoryRouteParams';

import {
    isValidSignature,
    UPLOAD_FILE_MAX_SIZE,
    SHOW_DATE_CHANGE_THRESHOLD_DIFFERENCE_IN_HOURS,
    BYTES_IN_KILOBYTES,
} from './PdmHelpers';

import DashErosImg from '../../assets/images/DASH-Eros-Device.png';
import Button from '../button/Button';
import Loading from '../loading/Loading';
import AlertModal from '../alertModal/alertModal';
import { setActivePage, setPdmUploadFailCount } from '../../stores/appStore/appActions';
import {
    PdmUploadState,
    PdmPreviousActionState,
    ModalType,
    UserRole,
    BrowserOperatingSystem,
    Resource,
    NotificationSeverity,
    INotification,
} from '../../models/app.models';
import { RootStateType } from '../../stores/store';
import PromptModal from '../promptModal/promptModal';
import PatientInfo from '../patientInfo/PatientInfo';
import Header from '../header/Header';
import { getOperatingSystem } from '../../helpers/operatingSystem';
import InstructionsModal from '../instructionsModal/InstructionsModal';

// TODO: Add currentFile to state and initiate parsing as effect when currentFile is set.

const PATIENT_ID_PARAM_NAME = 'patientId';

const PdmUploader = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation<{ patient: IDetailedPatients | IPatientsTableRows }>();
    const dispatch = useDispatch();
    const searchPatientId = new URLSearchParams(location.search).get(PATIENT_ID_PARAM_NAME);

    const { pdmUploadFailCount, role: activeRole } = useSelector((state: RootStateType) => state.appState);

    const [loading, setLoading] = useState<boolean>(false);
    const [patient, setPatient] = useState<IDetailedPatients | IPatientsTableRows | null>(
        location.state?.patient ?? null
    );
    const [hasInvalidState, setInvalidState] = useState<boolean>(false);
    const [fileId, setFileId] = useState<string | null>(null);
    const [fileSize, setFileSize] = useState<string | null>(null);
    const [metadata, setMetadata] = useState<UploadedFileMetadata | null>(null);
    const [modalProps, setModalProps] = useState<{
        description: string;
        title: string;
        type: ModalType;
    } | null>(null);

    const { acceptedFiles, fileRejections, getInputProps, open } = useDropzone({
        multiple: false,
        noClick: true,
        noKeyboard: true,
        accept: {
            'application/ibf': ['.ibf'],
        },
        maxSize: UPLOAD_FILE_MAX_SIZE,
    });

    const redirectToPatientsDirectory = useCallback(
        (patientId?: string, notification?: INotification) => {
            const path = activeRole === UserRole.Admin ? RoutePath.adminPatients : RoutePath.patients;
            history.push({
                pathname: path,
                ...(patientId && { search: `${SELECTED_PATIENT_ID_PARAM_NAME}=${patientId}` }),
                ...(notification && { state: { notification } }),
            });
        },
        [activeRole, history]
    );

    const loadMetadata = async () => {
        if (!fileId) {
            setInvalidState(true);
            return;
        }

        const loadedMetadata = await getUploadMetadata(fileId);

        if (loadedMetadata) {
            setMetadata(loadedMetadata);
        } else {
            setInvalidState(true);
        }
    };

    const getFileSizeString = (fileSizeInBytes: number) => {
        return `${(fileSizeInBytes / BYTES_IN_KILOBYTES).toFixed(0)} kB`;
    };

    const sendSuccessUploadAnalyticsEvent = () => {
        if (patient && metadata && fileSize)
            sendAzureEvent(analyticsEventNames.SUCCESS_DASH_EROS_PDM_DATA_UPLOAD, {
                fileSize,
                dataDays: String(metadata.data.availableDays),
                deviceType: patient.deviceClass,
            });
    };

    const determineNextStepAfterParse = async () => {
        if (!fileId || !metadata || !fileSize) {
            setInvalidState(true);
            return;
        }

        const downloadDate = moment(new Date(metadata.data.downloadDate));
        const currentDate = moment(new Date());
        const differenceInHours = currentDate.diff(downloadDate, 'hours');

        if (Math.abs(differenceInHours) > SHOW_DATE_CHANGE_THRESHOLD_DIFFERENCE_IN_HOURS) {
            if (!patient) {
                setInvalidState(true);
                return;
            }

            history.replace(
                {
                    pathname: RoutePath.pdmUploaderDateChange,
                    search: `?fileId=${fileId}&patientId=${patient.id}`,
                },
                {
                    patient,
                    metadata,
                    fileSize,
                }
            );
        } else {
            /* Cannot order methods in a way that this rule is not violated. A set of methods
                in this component call each other in circular fashion.
            */

            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            initiateLoading();
        }
    };

    const sendUnsuccessfulProcessingOutcomeAnalyticsEvent = (
        analyticsEventName: string,
        processingOutcome: PdmUploadState
    ) => {
        let cause = 'unknown';

        if (processingOutcome === PdmUploadState.Pending) cause = 'parsing file soft failure';
        if (processingOutcome === PdmUploadState.Confirmed) cause = 'loading data soft failure';
        if (processingOutcome === PdmUploadState.LoadingFailed) cause = 'loading data hard failure';
        if (processingOutcome === PdmUploadState.ParsingFailed) cause = 'parsing file hard failure';

        if (fileSize && patient)
            sendAzureEvent(analyticsEventName, {
                fileSize,
                deviceType: patient.deviceClass,
                cause,
            });
    };

    const sendNetworkConnectionErrorAnalyticsEvent = (
        currentFileSize: string,
        isRequestTimeout: boolean,
        isNetworkError: boolean
    ) => {
        let cause = 'unknown';
        if (isRequestTimeout) cause = 'timeout';
        if (isNetworkError) cause = 'network error';

        if (patient)
            sendAzureEvent(
                analyticsEventNames.FAILURE_NETWORK_CONNECTION_ERROR_DURING_FILE_UPLOAD_DASH_EROS_PDM_DATA_UPLOAD,
                {
                    fileSize: currentFileSize,
                    deviceType: patient.deviceClass,
                    cause,
                }
            );
    };

    const checkProcessingOutcome = async (actionState: PdmPreviousActionState) => {
        if (!fileId) {
            setInvalidState(true);
            return;
        }

        const response: any = await getStateAfterAction(fileId, actionState);
        const parseState = response.data?.data?.item.state;

        switch (parseState) {
            case PdmUploadState.Pending:
            case PdmUploadState.Confirmed:
            case PdmUploadState.LoadingFailed:
            case PdmUploadState.ParsingFailed: {
                setLoading(false);
                const newPdmUploadFailCount = pdmUploadFailCount + 1;

                dispatch(setPdmUploadFailCount(newPdmUploadFailCount));

                if (newPdmUploadFailCount > 1) {
                    sendUnsuccessfulProcessingOutcomeAnalyticsEvent(
                        analyticsEventNames.FAILURE_NOT_FIRST_IN_SESSION_UNSUCCESSFUL_PROCESSING_OUTCOME_DASH_EROS_PDM_DATA_UPLOAD,
                        parseState
                    );
                    setModalProps({
                        description: t(
                            `pdmUploader.${
                                isPauseItEnabled() ? 'cannotProcessDescriptionPauseIt' : 'cannotProcessDescription'
                            }`
                        ),
                        title: t(
                            `pdmUploader.${isPauseItEnabled() ? 'cannotProcessTitlePauseIt' : 'cannotProcessTitle'}`
                        ),
                        type: ModalType.Prompt,
                    });
                } else {
                    sendUnsuccessfulProcessingOutcomeAnalyticsEvent(
                        analyticsEventNames.FAILURE_FIRST_IN_SESSION_UNSUCCESSFUL_PROCESSING_OUTCOME_DASH_EROS_PDM_DATA_UPLOAD,
                        parseState
                    );
                    setModalProps({
                        description: t('pdmUploader.firstTimeCannotProcessDescription'),
                        title: t('pdmUploader.firstTimeCannotParseTitle'),
                        type: ModalType.Alert,
                    });
                }

                break;
            }
            case PdmUploadState.Preview: {
                loadMetadata();
                break;
            }
            case PdmUploadState.Loaded: {
                if (!patient || !metadata) {
                    setInvalidState(true);
                } else {
                    sendSuccessUploadAnalyticsEvent();

                    const notification = {
                        severity: NotificationSeverity.Success,
                        message: t('pdmUploader.successfulUploadMessage', {
                            first: patient.firstName,
                            last: patient.lastName,
                            days: metadata.data.availableDays,
                        }),
                    };

                    redirectToPatientsDirectory(patient.id, notification);
                }
                break;
            }
            default:
        }
    };

    const onInitiateParsingError = (err: any, currentFileSize: string) => {
        setLoading(false);
        const isRequestTimeout = err.message.includes('timeout');
        const isNetworkError = err.message === 'Network Error';

        if (isRequestTimeout || isNetworkError) {
            sendNetworkConnectionErrorAnalyticsEvent(currentFileSize, isRequestTimeout, isNetworkError);
            setModalProps({
                description: t('pdmUploader.unknownErrorDescription'),
                title: t('pdmUploader.unknownErrorTitle'),
                type: ModalType.Alert,
            });
        } else {
            dispatch(setActivePage(ErrorPages.recall));
        }
    };

    const submitFile = async (formData: FormData, currentFileSize: string) => {
        try {
            const res = await uploadFile(formData);
            const responseFileId = res?.data?.data?.item?.id;

            if (res.status === StatusCode.CREATED && responseFileId.length > 0) {
                setFileId(responseFileId);
            }
        } catch (err: any) {
            onInitiateParsingError(err, currentFileSize);
        }
    };

    const initiateParsing = async (ibfFile: File) => {
        if (!patient) {
            setInvalidState(true);
            return;
        }

        const currentFileSize = getFileSizeString(ibfFile.size);

        setFileSize(currentFileSize);
        setLoading(true);
        const formData = new FormData();

        formData.append('file', ibfFile as Blob);
        formData.append('patientId', patient.id);

        await submitFile(formData, currentFileSize);
    };

    const initiateLoading = async () => {
        if (!fileId) {
            setInvalidState(true);
            return;
        }

        await saveUploadedFileData(fileId);
        checkProcessingOutcome(PdmPreviousActionState.Load);
    };

    const onModalTryAgain = () => {
        setModalProps(null);
    };

    const onModalCancel = () => {
        setInvalidState(true);
    };

    const getInstructionsFilePath = () => {
        const operatingSystem = getOperatingSystem();

        switch (operatingSystem) {
            case BrowserOperatingSystem.Mac:
                return createHcpPublicPagesPath(`docs/omnipod-dash-eros-mac-locate-data-instructions.pdf`);
            case BrowserOperatingSystem.PC:
                return createHcpPublicPagesPath(`docs/omnipod-dash-eros-pc-locate-data-instructions.pdf`);
            default:
                return null;
        }
    };

    const instructionsFilePath = getInstructionsFilePath();

    const sendClickAnalyticsEvent = (analyticsEventName: string) => {
        if (patient) {
            sendAzureEvent(analyticsEventName, {
                deviceType: patient.deviceClass,
            });
        }
    };

    const handlePrintInstructionsClick = () => {
        if (instructionsFilePath) {
            sendClickAnalyticsEvent(analyticsEventNames.CLICK_PRINT_DASH_EROS_LOCATE_DATA_INSTRUCTIONS);
            printJS({ printable: instructionsFilePath });
        }
    };

    const handleReadInstructionsClick = () => {
        if (instructionsFilePath) {
            sendClickAnalyticsEvent(analyticsEventNames.CLICK_VIEW_DASH_EROS_LOCATE_DATA_INSTRUCTIONS);
            window.open(instructionsFilePath);
        }
    };

    const handleClickUploadButton = () => {
        sendClickAnalyticsEvent(analyticsEventNames.CLICK_UPLOAD_DATA_DASH_EROS_PDM_DATA_UPLOAD);
        open();
    };

    const loadPatient = useCallback(
        async (patientId: string) => {
            setLoading(true);
            const loadedPatient = await getPatientData(patientId, t);

            if (loadedPatient) {
                setPatient(loadedPatient);
            } else {
                setInvalidState(true);
            }

            setLoading(false);
        },
        [t]
    );

    const sendFileValidationFailureAnalyticsEvent = (
        analyticsEventName: string,
        currentFile: File,
        includeFileIdentifiers: boolean
    ) => {
        if (patient && currentFile)
            sendAzureEvent(analyticsEventName, {
                fileSize: getFileSizeString(currentFile.size),
                deviceType: patient.deviceClass,
                ...(includeFileIdentifiers && {
                    fileType: currentFile.type,
                    fileName: currentFile.name,
                }),
            });
    };

    const sendPdmConnectionErrorAnalyticsEvent = () => {
        if (patient)
            sendAzureEvent(
                analyticsEventNames.FAILURE_PDM_CONNECTION_ERROR_DURING_FILE_DOWNLOAD_DASH_EROS_PDM_DATA_UPLOAD,
                {
                    deviceType: patient.deviceClass,
                }
            );
    };

    useEffect(() => {
        (async () => {
            if (acceptedFiles.length > 0) {
                const currentFile = acceptedFiles[0];
                let isValidSignatureFile: boolean;

                try {
                    const buffer = await currentFile.arrayBuffer();
                    isValidSignatureFile = await isValidSignature(buffer);
                } catch {
                    sendPdmConnectionErrorAnalyticsEvent();
                    setModalProps({
                        description: t('pdmUploader.unknownErrorDescription'),
                        title: t('pdmUploader.unknownErrorTitle'),
                        type: ModalType.Alert,
                    });
                    return;
                }

                if (isValidSignatureFile) {
                    initiateParsing(currentFile);
                } else {
                    sendFileValidationFailureAnalyticsEvent(
                        analyticsEventNames.FAILURE_FILE_SIGNATURE_ERROR_DASH_EROS_PDM_DATA_UPLOAD,
                        currentFile,
                        false
                    );
                    setModalProps({
                        description: t('pdmUploader.invalidFileDescription'),
                        title: t('pdmUploader.invalidFileTitle'),
                        type: ModalType.Alert,
                    });
                }
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);

    useEffect(() => {
        (async () => {
            if (fileRejections.length > 0) {
                const currentFile = fileRejections[0].file;
                switch (fileRejections[0].errors[0].code) {
                    case ErrorCode.FileInvalidType:
                        sendFileValidationFailureAnalyticsEvent(
                            analyticsEventNames.FAILURE_FILE_TYPE_ERROR_DASH_EROS_PDM_DATA_UPLOAD,
                            currentFile,
                            true
                        );
                        setModalProps({
                            description: t('pdmUploader.invalidFileDescription'),
                            title: t('pdmUploader.invalidFileTitle'),
                            type: ModalType.Alert,
                        });

                        break;
                    case ErrorCode.FileTooLarge:
                        sendFileValidationFailureAnalyticsEvent(
                            analyticsEventNames.FAILURE_FILE_TOO_LARGE_ERROR_DASH_EROS_PDM_DATA_UPLOAD,
                            currentFile,
                            false
                        );
                        setModalProps({
                            description: t('pdmUploader.fileTooLargeDescription'),
                            title: t('pdmUploader.fileTooLargeTitle'),
                            type: ModalType.Alert,
                        });

                        break;
                    default:
                        setModalProps({
                            description: t('pdmUploader.unknownErrorDescription'),
                            title: t('pdmUploader.unknownErrorTitle'),
                            type: ModalType.Alert,
                        });
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileRejections]);

    useEffect(() => {
        if (patient) {
            return;
        }

        if (searchPatientId) {
            loadPatient(searchPatientId);
            return;
        }

        setInvalidState(true);
    }, [patient, searchPatientId, loadPatient]);

    useEffect(() => {
        if (hasInvalidState) {
            redirectToPatientsDirectory();
        }
    }, [hasInvalidState, patient, redirectToPatientsDirectory]);

    useEffect(() => {
        if (fileId) {
            checkProcessingOutcome(PdmPreviousActionState.Parse);
        }
    }, [fileId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (metadata) {
            determineNextStepAfterParse();
        }
    }, [metadata]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) return <Loading className="main-content" />;

    return (
        <div className="pdm-uploader">
            <Header title={t('pdmUploader.uploadData')} showBackButton />
            <div className="main-content pdm-uploader__container">
                <div className="pdm-uploader__instructions-container">
                    {patient && (
                        <PatientInfo firstName={patient?.firstName} lastName={patient?.lastName} dob={patient?.dob} />
                    )}
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <input {...getInputProps()} />
                    <Button
                        className="btn pdm-uploader__upload-button"
                        text={t('pdmUploader.uploadPatientData')}
                        dataTestId="pdm-upload-btn"
                        onClick={handleClickUploadButton}
                    />

                    <p className="pdm-uploader__instructions-text">{t('pdmUploader.uploaderInstruction')}</p>
                    <div className="pdm-uploader__instructions-divider" />
                    <div className="pdm-uploader__instructions-actions">
                        <Button
                            className="btn pdm-uploader__actions-button"
                            text={t('pdmUploader.printInstructions')}
                            dataTestId="pdm-uploader-print-instructions"
                            startIcon={<PrintOutlinedIcon />}
                            onClick={handlePrintInstructionsClick}
                        />
                        <Button
                            className="btn pdm-uploader__actions-button"
                            text={t('pdmUploader.readInstructions')}
                            dataTestId="pdm-uploader-read-instructions"
                            startIcon={<DescriptionOutlinedIcon />}
                            onClick={handleReadInstructionsClick}
                        />
                    </div>
                </div>
                <img src={DashErosImg} alt={t('pdmUploader.uploadPdmData')} className="pdm-uploader__dash-eros-img" />
            </div>
            {instructionsFilePath && (
                <InstructionsModal
                    title={t('pdmUploader.instructionsTitle')}
                    contentTitle={t('pdmUploader.instructionsContentIframeTitle')}
                    contentFilePath={instructionsFilePath}
                    onPrint={handlePrintInstructionsClick}
                    resource={Resource.dashErosLocateDataInstructions}
                />
            )}
            {modalProps?.type === ModalType.Alert && (
                <AlertModal
                    description={modalProps.description}
                    isOpen
                    okButtonLabel="errorModal.tryAgain"
                    onClose={onModalTryAgain}
                    title={modalProps.title}
                />
            )}
            {modalProps?.type === ModalType.Prompt && (
                <PromptModal
                    description={modalProps.description}
                    isOpen
                    okButtonLabel="errorModal.tryAgain"
                    onClose={onModalCancel}
                    onOkClick={onModalTryAgain}
                    title={modalProps.title}
                />
            )}
        </div>
    );
};

export default PdmUploader;
