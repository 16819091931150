import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { sendAzureEvent } from 'src/helpers/appInsights';
import { links, socialNetworks } from 'src/models/consts/footerLinks';

import footerLogo from '../../assets/images/logoFooter.svg';
import { FooterNavigationElement } from '../../models/app.models';

const Footer = () => {
    const { t } = useTranslation();

    const handleAnalyticsEvent = (eventName: string, link?: string) => {
        sendAzureEvent(eventName, { link: `${link}` });
    };

    const getLink = (link: FooterNavigationElement) => {
        if (link.type === 'placeholder') return <li key={uuidv4()} className="footer__link" />;
        return (
            <li //eslint-disable-line
                key={uuidv4()}
                className="footer__link"
                data-testid={t(`${link.title}`)}
                onClick={() => handleAnalyticsEvent(link.analyticsEventName, link.url)}
            >
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {t(`${link.title}`)}
                </a>
            </li>
        );
    };

    return (
        <div className="footer">
            <div className="footer__container">
                <div className="footer__content">
                    <div className="footer__logo-wrap">
                        <div className="footer__logo">
                            <img src={footerLogo} alt="footerLogo" />
                        </div>
                        <div className="footer__soc-net">
                            {socialNetworks.map((link, i) => (
                                <a
                                    key={uuidv4()}
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-testid={`socNet-${i}`}
                                    onClick={() => handleAnalyticsEvent(link.analyticsEventName, link.url)}
                                >
                                    <img src={link.icon} alt="a" />
                                </a>
                            ))}
                        </div>
                    </div>

                    <ul className="footer__links">{links.map(getLink)}</ul>
                </div>
                <div className="footer__descr">{t('footer.description')}</div>
            </div>
        </div>
    );
};

export default Footer;
