import { useTranslation } from 'react-i18next';

import FindDateInstructions from '../findDateInstructions/FindDateInstructions';
import howToOpenSettingsErosImage from '../../assets/images/howToOpenSettingsEros.png';

interface IFindDateInstructionsEros {
    onOpen: () => void;
}

const FindDateInstructionsEros = ({ onOpen }: IFindDateInstructionsEros) => {
    const { t } = useTranslation();

    return (
        <FindDateInstructions text={t('PDMProduct.eros')} placement="bottom-end" onOpen={onOpen}>
            <div className="find-date-instructions-tooltip">
                <img
                    data-testid="instructionsImage"
                    src={howToOpenSettingsErosImage}
                    alt={t('pdmUploaderDateChange.instructionsImageAltText')}
                />
                <div>
                    <p data-testid="erosInstructionsTitle" className="title">
                        {t('PDMProduct.eros')}
                    </p>
                    <p className="navigation">{t('pdmUploaderDateChange.pdmNavigation')}</p>
                    <p>{t('pdmUploaderDateChange.erosDate')}</p>
                </div>
            </div>
        </FindDateInstructions>
    );
};

export default FindDateInstructionsEros;
