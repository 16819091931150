import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useLocation } from 'react-router';
import { useEffect, useState, useCallback, useMemo, KeyboardEvent, ChangeEvent } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';

import { analyticsEventNames } from 'src/models/consts/analytics';
import { getPatientsData, getPatientsWithDeviceStatisticsAndPatientsInvitations } from 'src/helpers/services/Patients';
import { RootStateType } from 'src/stores/store';
import { RoutePath, IPatients, IPatientsTableRows, PatientStatus } from 'src/models/table.models';
import { SELECTED_PATIENT_ID_PARAM_NAME, PAGE_PARAM_NAME } from 'src/models/consts/patientDirectoryRouteParams';
import { sendAzureEvent } from 'src/helpers/appInsights';
import { setRowsPerPage } from 'src/stores/appStore/appActions';
import createPdfUrl, { AccessPdfAction } from 'src/helpers/services/createPdfUrl';
import splitStringByCharIndex from 'src/helpers/splitStringByCharIndex';

import { PdmDeviceClasses, UserRole } from '../../models/app.models';
import AlertModal from '../alertModal/alertModal';
import Button from '../button/Button';
import Input from '../input/Input';
import Loading from '../loading/Loading';
import Notification from '../notification/Notification';
import Pagination from '../pagination/Pagination';
import PatientsTable from '../patientsTable/PatientsTable';
import PromptModal from '../promptModal/promptModal';
import SplitButton from '../splitButton/SplitButton';
import TextFieldSelect from '../textFieldSelect/TextFieldSelect';
import {
    findSelectedReportDaysByDaysMenuItemText,
    getNewReportDaysSelectMenuItems,
    getNewShowNoDataModalValue,
} from '../../helpers/patientDirectory';
import {
    FOURTEEN_REPORT_DAYS_MENU_ITEM,
    INITIAL_REPORT_DAYS_SELECT_MENU_ITEMS,
    INITIAL_SELECTED_REPORT_DAYS,
    LAST_UPLOAD_THRESHOLD_IN_HOURS,
    LESS_THAN_SEVEN_REPORT_DAYS_MENU_ITEM,
    SEARCH_BOX_MAXIMUM_DROP_DOWN_ITEMS,
    SEARCH_BOX_MINIMUM_PREFIX_LENGTH,
    SEVEN_REPORT_DAYS_MENU_ITEM,
    TABLE_COLUMNS,
} from '../../models/consts/patientDirectory';
import { IReportDaysSelectMenuItem } from '../../models/patientDirectory.model';

const PatientDirectory = () => {
    const { t } = useTranslation();
    const location = useLocation<{ patientId: string } | null>();
    const history = useHistory();

    const activeRole = useSelector((state: RootStateType) => state.appState.role);

    const oktaData = useSelector((state: RootStateType) => state.appState.oktaData);
    const rowsPerPage = useSelector((state: RootStateType) => state.appState.rowsPerPage);

    const dispatch = useDispatch();

    const locationParams = useMemo(() => new URLSearchParams(location.search), [location]);

    const [isInitialLoad, setInitialLoad] = useState(true);
    const [allPatients, setAllPatients] = useState<IPatients[]>();
    const [filteredPatients, setFilteredPatients] = useState<IPatients[]>([]);
    const [displayedPatientsDetails, setDisplayedPatientsDetails] = useState<IPatientsTableRows[]>();
    const [filteredLastNames, setFilteredLastNames] = useState<string[]>([]);
    const [selectedPatientId, setSelectedPatientId] = useState('');
    const [reportDaysSelectMenuItems, setReportDaysSelectMenuItems] = useState<IReportDaysSelectMenuItem[]>(
        INITIAL_REPORT_DAYS_SELECT_MENU_ITEMS
    );
    const [loading, setLoading] = useState(true);
    const [selectedReportDays, setSelectedReportDays] =
        useState<IReportDaysSelectMenuItem>(INITIAL_SELECTED_REPORT_DAYS);
    const [showNoDataModal, setShowNoDataModal] = useState(false);
    const [showRecentUploadModal, setShowRecentUploadModal] = useState(false);
    const [lastNameStartsWith, setLastNameStartsWith] = useState('');
    const [page, setPage] = useState(0);

    const loadAllPatients = useCallback(async () => {
        setLoading(true);
        const { items: loadedPatients } = await getPatientsData(t);
        setAllPatients(loadedPatients);
        setLoading(false);
    }, [t]);

    const loadDisplayedPatientsDetails = async (
        actualPage: number,
        actualRowsPerPage: number,
        actualFilteredPatients: IPatients[] = []
    ) => {
        const displayedPatients = [...actualFilteredPatients].splice(actualPage * actualRowsPerPage, actualRowsPerPage);

        if (displayedPatients.length) {
            setLoading(true);
            const loadedDisplayedPatientsDetails = await getPatientsWithDeviceStatisticsAndPatientsInvitations(
                displayedPatients
            );
            setDisplayedPatientsDetails(loadedDisplayedPatientsDetails);
            setLoading(false);
        } else {
            setDisplayedPatientsDetails([]);
        }
    };

    const loadSelectedReportDays = useCallback(
        (patient: IPatientsTableRows, newReportDaysSelectMenuItems: IReportDaysSelectMenuItem[]) => {
            let daysMenuItemText;

            if (patient.days < LESS_THAN_SEVEN_REPORT_DAYS_MENU_ITEM.value) {
                daysMenuItemText = LESS_THAN_SEVEN_REPORT_DAYS_MENU_ITEM.text;
            } else if (patient.days < FOURTEEN_REPORT_DAYS_MENU_ITEM.value) {
                daysMenuItemText = SEVEN_REPORT_DAYS_MENU_ITEM.text;
            } else {
                daysMenuItemText = FOURTEEN_REPORT_DAYS_MENU_ITEM.text;
            }

            const newSelectedReportDays: IReportDaysSelectMenuItem =
                findSelectedReportDaysByDaysMenuItemText(newReportDaysSelectMenuItems, daysMenuItemText) ??
                INITIAL_SELECTED_REPORT_DAYS;

            setSelectedReportDays(newSelectedReportDays);
        },
        []
    );

    const loadNewReportDaysSelectMenuItems = useCallback(
        (patient: IPatientsTableRows) => {
            const newReportDaysSelectMenuItems: IReportDaysSelectMenuItem[] = getNewReportDaysSelectMenuItems(patient);

            loadSelectedReportDays(patient, newReportDaysSelectMenuItems);
            setReportDaysSelectMenuItems(newReportDaysSelectMenuItems);
        },
        [loadSelectedReportDays]
    );

    const getPageByPatientId = useCallback((patients: IPatients[], currentRowsPerPage: number, patientId: string) => {
        const patientsIndex = patients.findIndex((patient) => patient.id === patientId);
        return patientsIndex === -1 ? 0 : Math.floor(patientsIndex / currentRowsPerPage);
    }, []);

    const getCappedPage = useCallback((patients: IPatients[], currentRowsPerPage: number, rawPage: number) => {
        const maxPage = Math.ceil(patients.length / currentRowsPerPage);
        const shiftedMaxPage = Math.max(maxPage - 1, 0);
        const cappedPage = Math.min(rawPage, shiftedMaxPage);

        return cappedPage;
    }, []);

    const findPatientDetailsById = (patientId: string, patientsDetails: IPatientsTableRows[] = []) => {
        return patientsDetails.find((patientDetails) => patientDetails.id === patientId);
    };

    const doesSelectedPatientHaveNoDataAndNoSettings = () => {
        const selectedPatient = findPatientDetailsById(selectedPatientId, displayedPatientsDetails);

        if (!selectedPatient) {
            return false;
        }

        const doesSelectedPatientHaveSettings = selectedPatient.hasDeviceSettings;
        const doesSelectedPatientHaveData = selectedPatient.days > 0;

        return !doesSelectedPatientHaveSettings && !doesSelectedPatientHaveData;
    };

    const isReportSelectionAreaElementDisabled = () => {
        const selectedPatient = findPatientDetailsById(selectedPatientId, displayedPatientsDetails);

        if (!selectedPatient) {
            return true;
        }

        const isInvitedPatientSelected = selectedPatient?.status === PatientStatus.invited;

        return isInvitedPatientSelected || doesSelectedPatientHaveNoDataAndNoSettings();
    };

    const selectPatient = useCallback(
        (patientId: string, patientsDetails: IPatientsTableRows[] = []) => {
            const patient = findPatientDetailsById(patientId, patientsDetails);

            if (!patient) {
                return;
            }

            const newShowNoDataModalValue = getNewShowNoDataModalValue(patient);

            setShowNoDataModal(newShowNoDataModalValue);
            loadNewReportDaysSelectMenuItems(patient);
            setSelectedPatientId(patientId);
        },
        [loadNewReportDaysSelectMenuItems]
    );

    const clearPatientSelection = () => {
        setSelectedPatientId('');
        setReportDaysSelectMenuItems(INITIAL_REPORT_DAYS_SELECT_MENU_ITEMS);
        setSelectedReportDays(INITIAL_SELECTED_REPORT_DAYS);
    };

    const clearFilteredLastNames = () => {
        setFilteredLastNames([]);
    };

    const showFilteredPatients = (
        newLastNameStartWith: string,
        actualRowsPerPage: number,
        actualAllPatients: IPatients[] = []
    ) => {
        setLastNameStartsWith(newLastNameStartWith);

        const newFilteredPatients = !newLastNameStartWith
            ? actualAllPatients
            : actualAllPatients.filter((patient) =>
                  patient.lastName.toLowerCase().startsWith(newLastNameStartWith.toLowerCase())
              );
        setFilteredPatients(newFilteredPatients);

        const newPage = 0;
        setPage(newPage);
        loadDisplayedPatientsDetails(newPage, actualRowsPerPage, newFilteredPatients);

        clearFilteredLastNames();
        clearPatientSelection();
    };

    const showFilteredLastNames = (newLastNameStartsWith: string, patients: IPatients[] = []) => {
        const allLastNames = patients.map((patient) => patient.lastName);
        const lastNamesStartingWith = allLastNames.filter((lastName) =>
            lastName.toLowerCase().startsWith(newLastNameStartsWith.toLowerCase())
        );
        const uniqueLastNamesStartingWith = new Set(lastNamesStartingWith);
        const newFilteredLastNames = [...uniqueLastNamesStartingWith].slice(0, SEARCH_BOX_MAXIMUM_DROP_DOWN_ITEMS);
        setFilteredLastNames(newFilteredLastNames);
    };

    const confirmDataUpload = () => {
        setShowRecentUploadModal(true);
    };

    const continueDataUpload = (patient: IPatients) => {
        history.push(
            {
                pathname: RoutePath.pdmUploader,
                search: `?patientId=${patient?.id}`,
            },
            { patient }
        );
    };

    const getSelectedPatientName = (patientId: string, patientsDetails: IPatientsTableRows[] = []) => {
        const selectedPatient = findPatientDetailsById(patientId, patientsDetails);
        return { first: selectedPatient?.firstName, last: selectedPatient?.lastName };
    };

    const sendClickReportActionAnalyticsEvent = useCallback(
        (analyticsEventName: string) => {
            const selectedPatient = findPatientDetailsById(selectedPatientId, displayedPatientsDetails);

            if (!selectedPatient) {
                return;
            }

            sendAzureEvent(analyticsEventName, {
                numberOfDays: String(selectedReportDays.value),
                pdmClass: selectedPatient.deviceClass,
            });
        },
        [displayedPatientsDetails, selectedReportDays, selectedPatientId]
    );

    const handleClickViewReport = useCallback(() => {
        sendClickReportActionAnalyticsEvent(analyticsEventNames.CLICK_VIEW_REPORT);
        const pdfUrl = createPdfUrl(selectedPatientId, selectedReportDays.value, AccessPdfAction.View, oktaData?.token);
        window.open(pdfUrl);
    }, [oktaData?.token, selectedReportDays, selectedPatientId, sendClickReportActionAnalyticsEvent]);

    const handleClickDownloadReport = useCallback(() => {
        sendClickReportActionAnalyticsEvent(analyticsEventNames.CLICK_DOWNLOAD_REPORT);
        const pdfUrl = createPdfUrl(
            selectedPatientId,
            selectedReportDays.value,
            AccessPdfAction.Download,
            oktaData?.token
        );
        window.open(pdfUrl);
    }, [oktaData?.token, selectedReportDays, selectedPatientId, sendClickReportActionAnalyticsEvent]);

    const handleClickPrintReport = useCallback(async () => {
        sendClickReportActionAnalyticsEvent(analyticsEventNames.CLICK_PRINT_REPORT);
        const pdfUrl = createPdfUrl(
            selectedPatientId,
            selectedReportDays.value,
            AccessPdfAction.Print,
            oktaData?.token
        );
        const myWindow = window.open(pdfUrl, '', 'height=700,width=750');

        setTimeout(() => {
            if (myWindow) {
                window.onfocus = () => {
                    myWindow.close();
                };
            }
        }, 100);
    }, [oktaData?.token, selectedReportDays, selectedPatientId, sendClickReportActionAnalyticsEvent]);

    const handleClickReportSelectionArea = () => {
        if (selectedPatientId && doesSelectedPatientHaveNoDataAndNoSettings()) {
            setShowNoDataModal(true);
        }
    };

    const handleCloseNoDataModal = () => {
        setShowNoDataModal(false);
    };

    const handleCloseRecentUploadModal = () => {
        setShowRecentUploadModal(false);
    };

    const handleOkRecentUploadModal = () => {
        const selectedPatient = findPatientDetailsById(selectedPatientId, displayedPatientsDetails);
        if (selectedPatient) {
            continueDataUpload(selectedPatient);
        }
        setShowRecentUploadModal(false);
    };

    const handleSelectPatient = (patientId: string) => {
        selectPatient(patientId, displayedPatientsDetails);
    };

    const handleChangeReportDaysSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const newSelectedReportDaysText = event.target.value;
        const newSelectedReportDays = reportDaysSelectMenuItems.find(
            (menuItem) => menuItem.text === newSelectedReportDaysText
        );

        if (newSelectedReportDays) {
            setSelectedReportDays(newSelectedReportDays);
        }
    };

    const handleChangePage = (newPage: number, newRowsPerPage: number) => {
        setPage(newPage);
        dispatch(setRowsPerPage(newRowsPerPage));
        loadDisplayedPatientsDetails(newPage, newRowsPerPage, filteredPatients);
        clearPatientSelection();
    };

    const handleClearSearchBoxInput = () => {
        showFilteredPatients('', rowsPerPage, allPatients);
    };

    const handleClickSearchBoxDropDownItem = (newLastNameStartsWith: string) => {
        showFilteredPatients(newLastNameStartsWith, rowsPerPage, allPatients);
    };

    const handleChangeSearchBoxInput = (newLastNameStartsWith: string) => {
        setLastNameStartsWith(newLastNameStartsWith);

        const newLastNameStartsWithLength = newLastNameStartsWith.length;

        if (newLastNameStartsWithLength >= SEARCH_BOX_MINIMUM_PREFIX_LENGTH) {
            showFilteredLastNames(newLastNameStartsWith, allPatients);
        } else if (newLastNameStartsWithLength > 0) {
            clearFilteredLastNames();
        } else {
            showFilteredPatients('', rowsPerPage, allPatients);
        }
    };

    const handleKeyPressSearchBoxInput = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && lastNameStartsWith.length >= SEARCH_BOX_MINIMUM_PREFIX_LENGTH) {
            showFilteredPatients(lastNameStartsWith, rowsPerPage, allPatients);
        }
    };

    const directToPatientInvite = (patientId?: string) => {
        let newLocation = RoutePath.patientsInvite;

        if (activeRole === UserRole.Admin) {
            newLocation = RoutePath.adminPatientsInvite;
        }

        const searchParams = new URLSearchParams();

        if (page > 0) {
            searchParams.set('directoryPage', page.toString());
        }

        if (patientId) {
            searchParams.set('patientId', patientId);
        }

        history.push({
            pathname: newLocation,
            ...(searchParams.keys().next().value && { search: searchParams.toString() }),
        });
    };

    const handleReinviteLinkClick = (patientid: string) => {
        directToPatientInvite(patientid);
    };

    const handleClickInviteButton = () => {
        directToPatientInvite();
    };

    const handleUploadData = (): void => {
        const selectedPatient = findPatientDetailsById(selectedPatientId, displayedPatientsDetails);

        if (!selectedPatient) {
            return;
        }

        if (
            selectedPatient.deviceClass === PdmDeviceClasses.DASH ||
            selectedPatient.deviceClass === PdmDeviceClasses.Eros
        ) {
            sendAzureEvent(analyticsEventNames.CLICK_UPLOAD_DATA_PATIENT_DIRECTORY, {
                pdmClass: selectedPatient.deviceClass,
            });

            const { lastUploaded } = selectedPatient;
            if (lastUploaded && moment().diff(moment(lastUploaded), 'hours') < LAST_UPLOAD_THRESHOLD_IN_HOURS) {
                confirmDataUpload();
            } else {
                continueDataUpload(selectedPatient);
            }
        }
    };

    useEffect(() => {
        loadAllPatients();
    }, [loadAllPatients]);

    useEffect(() => {
        const locationSelectedPatientId = locationParams.get(SELECTED_PATIENT_ID_PARAM_NAME) || '';

        if (locationSelectedPatientId === selectedPatientId) {
            return;
        }

        if (isInitialLoad) {
            setSelectedPatientId(locationSelectedPatientId);
        } else {
            if (selectedPatientId) {
                locationParams.set(SELECTED_PATIENT_ID_PARAM_NAME, selectedPatientId);
            } else {
                locationParams.delete(SELECTED_PATIENT_ID_PARAM_NAME);
            }
            const newLocation = { ...location, search: locationParams.toString() };
            history.replace(newLocation);
        }
    }, [location, selectedPatientId, isInitialLoad, locationParams, history]);

    useEffect(() => {
        const locationPageString = locationParams.get(PAGE_PARAM_NAME);

        if ((locationPageString == null && page === 0) || (locationPageString === page.toString() && page !== 0)) {
            return;
        }

        let locationPage = Number(locationPageString || '0');
        locationPage = Number.isInteger(locationPage) && locationPage >= 0 ? locationPage : 0;
        if (isInitialLoad && !page) {
            setPage(locationPage);
        } else {
            if (page) {
                locationParams.set(PAGE_PARAM_NAME, page.toString());
            } else {
                locationParams.delete(PAGE_PARAM_NAME);
            }
            const newLocation = { ...location, search: locationParams.toString() };
            history.replace(newLocation);
        }
    }, [location, isInitialLoad, locationParams, history, page]);

    useEffect(() => {
        if (isInitialLoad && allPatients) {
            const newFilteredPatients = allPatients;
            setFilteredPatients(newFilteredPatients);

            const initialPage = selectedPatientId
                ? getPageByPatientId(newFilteredPatients, rowsPerPage, selectedPatientId)
                : getCappedPage(newFilteredPatients, rowsPerPage, page);

            setPage(initialPage);
            loadDisplayedPatientsDetails(initialPage, rowsPerPage, newFilteredPatients);
        }
    }, [isInitialLoad, allPatients, selectedPatientId, rowsPerPage, page, getCappedPage, getPageByPatientId]);

    useEffect(() => {
        if (isInitialLoad && displayedPatientsDetails) {
            if (selectedPatientId) {
                const isPatientSelected = findPatientDetailsById(selectedPatientId, displayedPatientsDetails);

                if (isPatientSelected) {
                    selectPatient(selectedPatientId, displayedPatientsDetails);
                } else {
                    clearPatientSelection();
                }
            }

            setInitialLoad(false);
        }
    }, [isInitialLoad, displayedPatientsDetails, selectedPatientId, selectPatient]);

    const generateSearchBoxDropDownElement = (patientLastName: string, boldValue: string) => {
        const [boldPart, normalPart] = splitStringByCharIndex(patientLastName, boldValue.length);
        return (
            <>
                <span className="bold">{boldPart}</span>
                {normalPart}
            </>
        );
    };

    const viewReportButtonMenuItems = useMemo(
        () => [
            {
                children: <span>{t('viewReport')}</span>,
                'data-testid': 'view-report-button',
                onClick: handleClickViewReport,
            },
            {
                children: <span>{t('printReport')}</span>,
                'data-testid': 'print-report-button',
                onClick: handleClickPrintReport,
            },
            {
                children: <span>{t('downloadReport')}</span>,
                'data-testid': 'download-report-button',
                onClick: handleClickDownloadReport,
            },
        ],
        [handleClickDownloadReport, handleClickPrintReport, handleClickViewReport, t]
    );

    return (
        <>
            <div className="main-content patientDirectory">
                <Button
                    className={clsx('btn', 'patientDirectory__invite-button')}
                    dataTestId="patient-directory__button-invite"
                    onClick={handleClickInviteButton}
                    text={t('patientDirectory.invitePatient')}
                />
                <h1 className="main-content__title">{t('patientDirectory.title')}</h1>
                <p className="main-content__description">{t('patientDirectory.description')}</p>
                <div className="patientDirectory__search patientDirectory__search_space-between">
                    <div className="patientDirectory__search-wrapper">
                        <Input
                            onChange={handleChangeSearchBoxInput}
                            onKeyPress={(e) => handleKeyPressSearchBoxInput(e)}
                            onClose={handleClearSearchBoxInput}
                            width={40}
                            Icon={SearchIcon}
                            inputValue={lastNameStartsWith}
                            placeholder={t('patientDirectory.placeholder')}
                            dataTestId="search"
                        />
                        <ul className="patientDirectory__matches">
                            {filteredLastNames.map((patientLastName) => (
                                <li
                                    className="patientDirectory__match"
                                    key={uuidv4()}
                                    aria-hidden="true"
                                    onClick={() => handleClickSearchBoxDropDownItem(patientLastName)}
                                >
                                    {generateSearchBoxDropDownElement(patientLastName, lastNameStartsWith)}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div
                        className="patientDirectory__search-right"
                        onClick={handleClickReportSelectionArea}
                        aria-hidden="true"
                    >
                        <div data-testid="select-wrapper">
                            <TextFieldSelect
                                className="patientDirectory__report-days-select"
                                data-testid="select-report-days"
                                disabled={isReportSelectionAreaElementDisabled()}
                                label={t('patientDirectory.selectReportDaysLabel')}
                                onChange={handleChangeReportDaysSelect}
                                value={selectedReportDays.text}
                            >
                                {reportDaysSelectMenuItems.map((menuItem) => (
                                    <MenuItem disabled={menuItem.isDisabled} key={uuidv4()} value={menuItem.text}>
                                        {t('patientDirectory.selectItem', { days: menuItem.text })}
                                    </MenuItem>
                                ))}
                            </TextFieldSelect>
                        </div>

                        <SplitButton
                            text={t('viewReport')}
                            disabled={isReportSelectionAreaElementDisabled()}
                            menuItems={viewReportButtonMenuItems}
                            onClick={handleClickViewReport}
                        />
                    </div>
                </div>
                {loading ? (
                    <Loading />
                ) : (
                    <div>
                        <PatientsTable
                            columns={TABLE_COLUMNS}
                            rows={displayedPatientsDetails ?? []}
                            activeRow={selectedPatientId}
                            onChoose={handleSelectPatient}
                            onUploadData={handleUploadData}
                            searchValue={lastNameStartsWith}
                            onInviteLinkClick={handleClickInviteButton}
                            onReinviteLinkClick={handleReinviteLinkClick}
                        />
                    </div>
                )}
                {filteredPatients?.length ? (
                    <Pagination
                        totalCount={filteredPatients.length}
                        rowRecordName={t('patientDirectory.patient')}
                        rowsPerPage={rowsPerPage}
                        activePage={page}
                        onChangePage={handleChangePage}
                    />
                ) : null}
            </div>

            <AlertModal
                description={t('lackOfData.description')}
                isOpen={showNoDataModal}
                okButtonLabel="ok"
                onClose={handleCloseNoDataModal}
                title={t('lackOfData.title')}
            />

            <PromptModal
                displayIcon={false}
                description={t('recentUpload.description')}
                isOpen={showRecentUploadModal}
                okButtonLabel={t('recentUpload.uploadAgain')}
                onClose={handleCloseRecentUploadModal}
                onOkClick={handleOkRecentUploadModal}
                title={t('recentUpload.title', getSelectedPatientName(selectedPatientId, displayedPatientsDetails))}
            />

            <Notification applyHomeHeaderOffset applySidebarOffset />
        </>
    );
};

export default PatientDirectory;
