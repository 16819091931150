import { Trans, useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';

import FindDateInstructions from '../findDateInstructions/FindDateInstructions';
import howToOpenSettingsDashImage from '../../assets/images/howToOpenSettingsDash.png';

interface IFindDateInstructionsDash {
    onOpen: () => void;
}

const FindDateInstructionsDash = ({ onOpen }: IFindDateInstructionsDash) => {
    const { t } = useTranslation();
    const settingsIcon = <SettingsIcon className="find-date-instructions-tooltip__icon" fontSize="small" />;

    return (
        <FindDateInstructions text={t('PDMProduct.dash')} onOpen={onOpen}>
            <div className="find-date-instructions-tooltip">
                <img
                    data-testid="instructionsImage"
                    src={howToOpenSettingsDashImage}
                    alt={t('pdmUploaderDateChange.instructionsImageAltText')}
                />
                <div>
                    <p data-testid="dashInstructionsTitle" className="title">
                        {t('PDMProduct.dash')}
                    </p>
                    <p className="navigation">{t('pdmUploaderDateChange.pdmNavigation')}</p>
                    <p>
                        <Trans t={t} i18nKey="pdmUploaderDateChange.dashDate" components={{ icon: settingsIcon }} />
                    </p>
                    <p>
                        <Trans t={t} i18nKey="pdmUploaderDateChange.dashTime" components={{ icon: settingsIcon }} />
                    </p>
                </div>
            </div>
        </FindDateInstructions>
    );
};

export default FindDateInstructionsDash;
