import { store } from '../stores/store';

const clinicIdRequestHeaderName = 'clinic-id' as const;

interface IGetClinicIdRequestHeader {
    [clinicIdRequestHeaderName]: string | null;
}

export const getClinicIdRequestHeader = (): IGetClinicIdRequestHeader => {
    const { appState } = store.getState();
    const { clinicId } = appState;

    return { [clinicIdRequestHeaderName]: clinicId };
};
