import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { IOktaData } from 'src/helpers/parseOktaData';

import { IBasal, IBolus, ICarbs } from './insulinUsageStatistics.models';
import {
    IGlucoseDisplayRange,
    IGlucoseRanges,
    IGlucoseChartPointInput,
    IPartsOfDay,
    ISummarySettingsDevice,
} from './summaryChart.model';
import {
    ICarbsPoint,
    IBolusPoint,
    IModesData,
    IDailyChartOptions,
    IDailyGlucose,
    IUTCOffset,
    IPodChange,
    IBasalSubmode,
    ITargetGlucosePoint,
    IBasalPoint,
} from './dailyChart.models';
import { IReportSettings } from './settings.model';
import { ISummaryBasalProfile } from './report.models';

export type AppStateType = {
    activePage: string;
    agreementStatus: AgreementStatus | null;
    clinicId: string | null;
    currentUser: IUser | null;
    oktaData: IOktaData | null;
    pdmUploadFailCount: number;
    role: string;
    rowsPerPage: number;
    signInUrl: string;
    shouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval: boolean;
};

export enum ButtonColors {
    Primary = 'primary',
    Secondary = 'secondary',
    Warning = 'warning',
}

export enum CurrentUserRole {
    Physician = 'physician',
    ClinicAdmin = 'clinic_admin',
    SuperAdmin = 'super_admin',
}

export enum ClinicalWorkerRole {
    Provider = 'physician',
    ClinicAdmin = 'clinicAdmin',
}

export enum ClinicalWorkerStatus {
    Active = 'active',
    Inactive = 'inactive',
    Invited = 'invited',
}

export enum UserRole {
    Admin = 'Admin',
    Doctor = 'Doctor',
    SuperAdmin = 'SuperAdmin',
}

export enum PdmUploadState {
    Pending = 'pending',
    ParsingFailed = 'parsingFailed',
    Preview = 'preview',
    Confirmed = 'confirmed',
    Loaded = 'loaded',
    LoadingFailed = 'loadingFailed',
}

export enum PdmPreviousActionState {
    Parse = 'parse',
    Load = 'load',
}

export enum ModalType {
    Alert = 'Alert',
    Prompt = 'Prompt',
}

export enum AgreementStatus {
    EULA = 'eula',
    CONFIRMED = 'confirmed',
}

export enum PdmDeviceClasses {
    Omnipod5 = 'omnipod5',
    DASH = 'dash',
    Eros = 'eros',
}

export enum DisplayedPdmDeviceClasses {
    Omnipod5 = 'Omnipod5',
    DASH = 'Dash',
    Eros = 'Eros',
}

export enum PrimaryMeasuringDeviceClasses {
    BloodGlucoseMeter = 'bloodGlucoseMeter',
    ContinuousGlucoseMonitor = 'continuousGlucoseMonitor',
}

export enum ComparisonOperator {
    GreaterThan = 'greaterThan',
    LessThan = 'lessThan',
    LessThanOrEqual = 'lessThanOrEqual',
    GreaterThanOrEqual = 'greaterThanOrEqual',
}

export enum BrowserOperatingSystem {
    PC = 'pc',
    Mac = 'mac',
}

export enum Resource {
    dashErosLocateDataInstructions = 'dashErosLocateDataInstructions',
}

export enum ResourceAction {
    reviewed = 'reviewed',
}

export enum NotificationSeverity {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
}

export enum ReportType {
    Daily = 'daily',
    Summary = 'summary',
    Settings = 'settings',
}

enum GlucoseRangeType {
    High = 'high',
    Low = 'low',
    Target = 'target',
    VeryHigh = 'veryHigh',
    VeryLow = 'veryLow',
}

export enum ApprovalStatus {
    Approved = 'approved',
    Deactivated = 'deactivated',
    Denied = 'denied',
    OverriddenByDoNotUse = 'overriddenByDoNotUse',
    Pending = 'pending',
}

export enum CgmDeviceClasses {
    DexcomG6 = 'dexcomG6',
    DexcomG7 = 'dexcomG7',
    Libre2 = 'libre2',
}

export enum MostRecentCgmDeviceClass {
    DexcomG6 = 'G6',
    DexcomG7 = 'G7',
    Libre2 = 'L2',
    Unspecified = 'UNSPECIFIED',
}

type GlucoseRangeLiteralType = `${GlucoseRangeType}`;

export interface IInviteClinicalWorker {
    email: string;
    name: IPersonName;
    type: ClinicalWorkerRole;
    clinicId?: string;
}

export interface IUpsertClinicalWorkerInvitation {
    email: string;
    name: IPersonName;
}

export interface IUpdateClinicalWorker {
    email: string;
    name: IPersonName;
    isActive: boolean;
}

export interface IUpdateSuperAdminPatient {
    birthdate: string;
    customerId?: string;
    deviceClass: PdmDeviceClasses;
    email: string;
    isActive: boolean;
    name: IPersonName;
    smsNumber?: string;
}

export interface ISuperAdminsClinicalWorker {
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    isActive: boolean;
    type?: ClinicalWorkerRole;
}

export interface ISuperAdminsPatient {
    firstName: string;
    lastName: string;
    birthdate: string;
    customerId?: string;
    deviceClass: PdmDeviceClasses;
    email: string;
    id: string;
    isActive: boolean;
    smsNumber?: string;
}

export interface IResource {
    name: Resource;
    actions: ResourceAction[];
}

export interface ISuperAdmin {
    email?: string;
    id: string;
    isActive: boolean;
    name: IPersonName;
    role?: CurrentUserRole;
}

export interface IMaterialTableRowData {
    tableData?: {
        editing?: string;
        id: string;
        index: number;
        uuid: string;
    };
}

export interface ICommonMeasurementUnits {
    basalRate: string;
    carbs: string;
    dailyBasalRate: string;
    glucose: string;
    insulin: string;
    insulinCorrectionFactor: string;
    insulinToCarbRatio: string;
}

export interface ICommonData {
    glucoseDisplayRange: IGlucoseDisplayRange;
    glucoseRanges: Record<GlucoseRangeLiteralType, IGlucoseDisplayRange>;
    insulinDeliveryModeHypoProtectTargetGlucose?: number;
    measurementUnits: ICommonMeasurementUnits;
}

export interface INotification {
    message: string;
    severity: NotificationSeverity;
    title?: string;
}

export interface IPatientData {
    device: IPdmDevice;
    birthdate: string | null;
    firstName: string | null;
    lastName: string | null;
    age: number | null;
    days: number | null;
    startDate: string | null;
    endDate: string | null;
    createdDate: string | null;
    glucoseUnits: string | null;
    modeHypoProtectTargetGlucose: number | null;
    dataCharacteristics: IDataCharacteristics;
    glucose: IGlucoseChartData;
    daily: IDayData[];
    timeInRange: ITimeInRangeData[] | null;
    glucoseStatistics: IDiscreteGlucoseStatistics[] | IContinuousGlucoseStatistics[];
    glucoseCountStatistics: IDiscreteGlucoseCountStatistics[];
    deviceUse: ISummaryDeviceUseData;
    glucoseRanges: IGlucoseRanges;
    targetGlucoseSettings: IGlucoseSettings;
    insulinCorrectionFactorSettings: IGlucoseSettings;
    insulinToCarbRatioSettings: IGlucoseSettings;
    correctAboveSettings: IGlucoseSettings;
    summarySettingsDevice: ISummarySettingsDevice;
    partsOfDay: IPartsOfDay;
    summaryBasalProfile: ISummaryBasalProfile;
    insulinStatistics: IInsulinUsageData | null;
    settings: IReportSettings | null;
    common: ICommonData;
}

export interface IPdmDevice {
    pdmDeviceClass: PdmDeviceClasses;
    primaryGlucoseMeasuringDeviceCategory: PrimaryMeasuringDeviceClasses;
    isPdmDeviceBolusCalculatorEnabled: boolean;
    continuousGlucoseMonitorDeviceClasses: CgmDeviceClasses[];
    mostRecentCgmDeviceClassUsed: MostRecentCgmDeviceClass | null;
}

export interface IGlucoseSettings {
    data: IChartDataWithDuration[];
    units: string;
    areSegmentsDisplayed: boolean;
}
export interface IChartDataWithDuration {
    x: number;
    duration: number;
    value: string;
}

export interface ITimeInRangeData {
    id: string;
    title: string;
    actualPercent: number;
    sumPercents: number;
    actualHours: number;
    actualMinutes: number;
    targetPercent: string | number;
    interval: string;
    units: string;
    position: string;
    isShowInTarget: boolean;
}

export interface IInsulinUsageData {
    total: number;
    basal: IBasal;
    bolus: IBolus;
    carbs: ICarbs;
}

export interface IContinuousGlucoseStatistics {
    title: string;
    value?: number;
    units: string;
    goal?: number;
    alternativeValue?: number;
    alternativeUnits?: string;
    isTranslateUnit?: boolean;
    isTranslateAlternativeUnit?: boolean;
    badge?: string;
    hideGoalWithNoValue?: boolean;
    noValueTemplate?: string;
    className?: string;
    note?: string;
}

export interface IDiscreteGlucoseStatistics {
    title: string;
    value?: number;
    units: string;
    subtitle?: string;
    range?: {
        lowest: number;
        highest: number;
    };
    goal?: number;
    badge?: string;
}

export interface IDiscreteGlucoseCountStatistics {
    title: string;
    value?: number;
}

export interface ISummaryDeviceUseData {
    data: IContinuousGlucoseStatistics[];
}
export interface IDaySectionTotal {
    total: number;
}
export interface IDaySectionData<IData> {
    data: IData;
}
export interface IDayDataSection<IData> extends IDaySectionTotal, IDaySectionData<IData> {}

export interface IDayData {
    options: IDailyChartOptions;
    carbs: IDayDataSection<ICarbsPoint[][]>;
    bolus: IDayDataSection<IBolusPoint[][]>;
    basal: IDayDataSection<IBasalPoint[]>;
    basalMode: IDaySectionData<IModesData[]>;
    basalSubmode: IDaySectionData<IBasalSubmode[]>;
    podChange: IDaySectionData<IPodChange[]>;
    date: string;
    glucose: IDailyGlucose;
    utcOffset?: IUTCOffset;
    hasData: boolean;
    targetGlucose: IDaySectionData<ITargetGlucosePoint[]>;
}

export interface IGlucoseChartData {
    statistics?: IGlucoseChartPointInput[];
    ranges: IGlucoseRanges;
    displayRange: IGlucoseDisplayRange;
    duration: number;
}

export interface IId {
    id: string;
}

export interface IDataCharacteristics {
    noSufficientForSummary: boolean;
    sufficiencyForSummaryCheckPeriodDays: number;
    summaryIsEmpty: boolean;
    noRecentDays: number;
    noRecentData: boolean;
}

export interface IPersonName {
    first: string;
    last: string;
}

export interface IUpsertPatientInvitation {
    birthdate: string;
    deviceClass: PdmDeviceClasses;
    email: string;
    name: IPersonName;
}

export interface IInvitePatient {
    birthdate: string;
    deviceClass: PdmDeviceClasses;
    email: string;
    name: IPersonName;
    physicianClinicalWorkerId: string;
}

export interface IMovePatientToClinic {
    birthdate: string;
    deviceClass: PdmDeviceClasses;
    email: string;
    name: IPersonName;
    physicianClinicalWorkerId: string;
}

export interface IClinic {
    id: string;
    name: string;
}

export interface IUser {
    approvalStatus: ApprovalStatus;
    email: string;
    id: string;
    isLatestEulaVersionAccepted: boolean;
    name: {
        first: string;
        last: string;
    };
    role: CurrentUserRole;
    resources: IResource[];
    clinic: IClinic;
}

export interface IPatientDeviceStatisticsItem {
    id: string;
    hasDeviceSettings: boolean;
    deviceData: {
        available: { days: number };
        last: { date: string };
        uploaded: { last: { date: string } };
    } | null;
    deviceClass: PdmDeviceClasses;
}

export interface IProvider {
    id: string;
    name: {
        first?: string;
        last?: string;
    };
    email?: string;
    nationalProviderIdentifier: string | null;
}

export type RetryErrorHandler = (response: AxiosResponse, retry?: () => void) => void;

export type AxiosRequestConfigWithRetry = AxiosRequestConfig & {
    retry?: number;
    retryErrorHandler: RetryErrorHandler;
};

export type FooterNavigationElement = FooterNavigationLink | FooterNavigationPlaceholderElement;

export interface FooterNavigationLink {
    title: string;
    analyticsEventName: string;
    url: string;
    type: 'link';
}

export interface FooterNavigationPlaceholderElement {
    type: 'placeholder';
}
