import axios from 'axios';

import { createApiPath } from 'src/helpers/createPath';

export async function getPatientInfoConfig(patientId: string, periodDays: number, timeZone: string) {
    const params = [];
    if (patientId) {
        params.push({ key: 'patientId', value: patientId });
    }
    params.push({ key: 'periodDays', value: String(periodDays) });
    params.push({ key: 'timeZone', value: String(timeZone) });
    try {
        const res = await axios.get(createApiPath('reports', params));
        return { data: res.data, status: res.status };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        return { data: null, status: error.response?.status };
    }
}
