import { useTranslation } from 'react-i18next';

import Button from '../button/Button';
import noPatientsImage from '../../assets/images/webImages/recall.png';

const RecallPage = () => {
    const { t } = useTranslation();

    const handleOnRecall = () => {
        window.location.reload();
    };

    return (
        <div className="main-content error-message">
            <img src={noPatientsImage} alt="noPatients" />
            <h2 className="error-message__title">{t('recall.title')}</h2>
            <p className="error-message__description">{t('recall.description')}</p>
            <Button onClick={handleOnRecall} text="recall.action" dataTestId="recall" />
        </div>
    );
};

export default RecallPage;
