import { useEffect } from 'react';

interface IUnmountListeningWrapperProps<T> {
    onUnmount: (value: T) => void;
    value: T;
    children: React.ReactNode;
}

function UnmountListeningWrapper<T>({ onUnmount, value, children }: IUnmountListeningWrapperProps<T>) {
    useEffect(() => {
        return () => {
            onUnmount(value);
        };
    }, [value, onUnmount]);

    return <>{children}</>;
}

export default UnmountListeningWrapper;
