import clsx from 'clsx';
import { Trans } from 'react-i18next';

import { ButtonColors } from 'src/models/app.models';

import Button from '../button/Button';
import Modal, { bemBlockName } from '../modal/Modal';
import exclamationPointIcon from '../../assets/images/exclamationPointIcon.svg';
import closeIcon from '../../assets/images/closeIcon.svg';

interface IPromptModalProps {
    children?: React.ReactNode;
    className?: string;
    description: string;
    displayIcon?: boolean;
    headerImage?: React.ReactNode;
    isOpen: boolean;
    okButtonLabel: string;
    okButtonColor?: ButtonColors;
    onClose: () => void;
    onOkClick: () => void;
    subtitle?: string;
    title: string;
}

function PromptModal({
    children,
    className,
    displayIcon,
    description,
    headerImage,
    isOpen,
    okButtonColor,
    okButtonLabel,
    onOkClick,
    onClose,
    subtitle,
    title,
}: IPromptModalProps) {
    if (!isOpen) {
        return null;
    }

    return (
        <Modal className={clsx(`${bemBlockName}-promptModal`, className)}>
            <>
                <img
                    alt="Close"
                    aria-hidden="true"
                    className={`${bemBlockName}__close`}
                    data-testid="PromptModalClose"
                    onClick={onClose}
                    src={closeIcon}
                />

                {displayIcon && !headerImage && (
                    <img
                        data-testid="ExclamationPointIcon"
                        className={`${bemBlockName}__iconExclamationPoint`}
                        src={exclamationPointIcon}
                        alt="Error"
                    />
                )}

                {headerImage}

                <div className={`${bemBlockName}__title`}>{title}</div>

                {subtitle && (
                    <div className={`${bemBlockName}__subtitle`} data-testid={`${bemBlockName}__subtitle`}>
                        {subtitle}
                    </div>
                )}

                <div className={`${bemBlockName}__descr-wrapper`}>
                    <div className={clsx(`${bemBlockName}__descr`, `${bemBlockName}__description-medium`)}>
                        <Trans components={{ bold: <b />, italic: <i /> }}>{description}</Trans>
                    </div>
                </div>

                {children}

                <div className={`${bemBlockName}__buttons`}>
                    <Button className="btn btn_white" dataTestId="cancelModal" onClick={onClose} text="cancel" />

                    <Button text={okButtonLabel} dataTestId="modalOk" color={okButtonColor} onClick={onOkClick} />
                </div>
            </>
        </Modal>
    );
}

PromptModal.defaultProps = {
    children: undefined,
    className: '',
    displayIcon: true,
    headerImage: undefined,
    okButtonColor: ButtonColors.Warning,
    subtitle: undefined,
};

export default PromptModal;
