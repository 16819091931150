import { getRuntimeConfig } from 'src/helpers/envReader';

import { analyticsEventNames } from './analytics';
import { variables } from './variables';

import { RoutePath } from '../table.models';

const clinicAdminPatientDirectoryItem = {
    title: 'sidebar.patients',
    analyticsEventName: analyticsEventNames.VIEW_PATIENTS,
    type: RoutePath.adminPatients,
};

const physicianPatientDirectoryItem = {
    title: 'sidebar.patients',
    analyticsEventName: analyticsEventNames.VIEW_PATIENTS,
    type: RoutePath.patients,
};

const resourcesSidebarSection = {
    title: 'sidebar.resources',
    items: [
        {
            title: 'sidebar.help',
            analyticsEventName: analyticsEventNames.VIEW_HELP,
            type: RoutePath.help,
        },
        {
            title: 'sidebar.about',
            analyticsEventName: analyticsEventNames.VIEW_ABOUT,
            type: RoutePath.about,
        },
    ],
};

const omnipodConnectSidebarSection = {
    title: 'sidebar.omnipodConnect',
    items: [
        {
            title: 'sidebar.home',
            type: RoutePath.home,
            analyticsEventName: analyticsEventNames.VIEW_HOME,
            link: getRuntimeConfig(variables.OMNIPOD_CONNECT_HOME_URL),
        },
        {
            title: 'sidebar.training',
            type: RoutePath.training,
            analyticsEventName: analyticsEventNames.VIEW_TRAINING,
            link: getRuntimeConfig(variables.OMNIPOD_CONNECT_TRAINING_URL),
        },
        // {
        //     title: 'sidebar.prescribe',
        //     type: RoutePath.prescribe,
        //     link: 'https://www.omnipod.com/hcp/reimbursement/how-to-prescribe-omnipod-dash',
        // },
    ],
};

export const clinicAdminPauseItOffSidebar = [
    {
        title: 'sidebar.directory',
        items: [
            clinicAdminPatientDirectoryItem,
            {
                title: 'sidebar.providers',
                analyticsEventName: analyticsEventNames.VIEW_PROVIDERS,
                type: RoutePath.adminProviders,
            },
        ],
    },
    {
        title: 'sidebar.pendingRequests',
        items: [
            {
                title: 'sidebar.patients',
                analyticsEventName: analyticsEventNames.VIEW_PENDING_PATIENTS,
                type: RoutePath.adminPendingPatients,
            },
            {
                title: 'sidebar.providers',
                analyticsEventName: analyticsEventNames.VIEW_PENDING_PROVIDERS,
                type: RoutePath.adminPendingProviders,
            },
        ],
    },
    resourcesSidebarSection,
    omnipodConnectSidebarSection,
];

export const clinicAdminPauseItOnSidebar = [
    {
        title: 'sidebar.directory',
        items: [clinicAdminPatientDirectoryItem],
    },
    resourcesSidebarSection,
];

export const defaultPauseItOffSidebar = [resourcesSidebarSection, omnipodConnectSidebarSection];
export const defaultPauseItOnSidebar = [resourcesSidebarSection];

export const notApprovedClinicAdminPauseItOffSidebar = [
    {
        title: 'sidebar.directory',
        items: [clinicAdminPatientDirectoryItem],
    },
    resourcesSidebarSection,
    omnipodConnectSidebarSection,
];

export const notApprovedClinicAdminPauseItOnSidebar = [
    {
        title: 'sidebar.directory',
        items: [clinicAdminPatientDirectoryItem],
    },
    resourcesSidebarSection,
];

export const notApprovedPhysicianPauseItOffSidebar = [
    {
        title: 'sidebar.directory',
        items: [physicianPatientDirectoryItem],
    },
    resourcesSidebarSection,
    omnipodConnectSidebarSection,
];

export const notApprovedPhysicianPauseItOnSidebar = [
    {
        title: 'sidebar.directory',
        items: [physicianPatientDirectoryItem],
    },
    resourcesSidebarSection,
];

export const physicianPauseItOffSidebar = [
    {
        title: 'sidebar.directory',
        items: [physicianPatientDirectoryItem],
    },
    resourcesSidebarSection,
    omnipodConnectSidebarSection,
];

export const physicianPauseItOnSidebar = [
    {
        title: 'sidebar.directory',
        items: [physicianPatientDirectoryItem],
    },
    resourcesSidebarSection,
];

export const superAdminSidebar = [
    {
        title: '',
        items: [
            {
                title: 'sidebar.superAdmins',
                analyticsEventName: analyticsEventNames.VIEW_SUPER_ADMINS,
                type: RoutePath.superAdmins,
            },
        ],
    },
    resourcesSidebarSection,
];
