import axios from 'axios';

import { createApiPath } from 'src/helpers/createPath';
import { ClinicalWorkerRole, IUpsertClinicalWorkerInvitation } from 'src/models/app.models';

export async function getApprovedPatientsDataConfig() {
    const res = await axios.get(createApiPath('patients'));
    return res?.data;
}

export async function getPendingPatientsData() {
    const res = await axios.get(createApiPath('admin/patients/pending'));
    return res?.data;
}

export async function approvePendingPatientsData(patientsId: { id: string }[]) {
    const approvePendingPatientsAxiosInstance = axios.create();

    await approvePendingPatientsAxiosInstance.post(createApiPath('admin/patients/approve'), patientsId);
}

export async function denyPendingPatientsData(patientsId: { id: string }[]) {
    const denyPendingPatientsAxiosInstance = axios.create();

    await denyPendingPatientsAxiosInstance.post(createApiPath('admin/patients/deny'), patientsId);
}

export async function getApprovedClinicalWorkersData() {
    const res = await axios.get(createApiPath('admin/clinical-workers/approved'));
    return res?.data;
}

export async function getPendingClinicalWorkersData() {
    const res = await axios.get(createApiPath('admin/clinical-workers/pending'));
    return res?.data;
}

export async function approvePendingClinicalWorkersData(clinicalWorkersId: { id: string }[]) {
    const approveClinicalWorkersAxiosInstance = axios.create();

    await approveClinicalWorkersAxiosInstance.post(createApiPath('admin/clinical-workers/approve'), clinicalWorkersId);
}

export async function denyPendingClinicalWorkersData(clinicalWorkersId: { id: string }[]) {
    const denyClinicalWorkersAxiosInstance = axios.create();

    await denyClinicalWorkersAxiosInstance.post(createApiPath('admin/clinical-workers/deny'), clinicalWorkersId);
}

export async function createClinicalWorkerInvitationData(data: IUpsertClinicalWorkerInvitation) {
    const inviteClinicalWorkerAxiosInstance = axios.create();

    const res = await inviteClinicalWorkerAxiosInstance.post(
        createApiPath('/admin/clinical-workers/invitations'),
        data
    );

    return res?.data;
}

export async function updateClinicalWorkerInvitationData(data: IUpsertClinicalWorkerInvitation, id: string) {
    const updateClinicalWorkerInvitationAxiosInstance = axios.create();

    const res = await updateClinicalWorkerInvitationAxiosInstance.put(
        createApiPath(`/admin/clinical-workers/invitations/${id}`),
        data
    );

    return res?.data;
}

export async function getClinicalWorkerInvitationData(id: string) {
    const res = await axios.get(createApiPath(`/admin/clinical-workers/invitations/${id}`));
    return res?.data;
}

export async function deactivateClinicalWorkerData(id: string) {
    await axios.post(createApiPath(`admin/clinical-workers/${id}/deactivate`));
}

export async function reactivateClinicalWorkerData(id: string) {
    await axios.post(createApiPath(`admin/clinical-workers/${id}/reactivate`));
}

export async function updateClinicalWorkerRoleData(id: string, role: ClinicalWorkerRole) {
    await axios.put(createApiPath(`admin/clinical-workers/${id}/role`), { role });
}

export async function getPendingPatientsAndClinicalWorkersCountsData() {
    const getPendingPatientsAndClinicalWorkersAxiosInstance = axios.create();
    const res = await getPendingPatientsAndClinicalWorkersAxiosInstance.get(
        createApiPath('admin/users/pending/counts')
    );
    return res?.data;
}

export async function removeClinicalWorkerInvitationData(id: string) {
    await axios.delete(createApiPath(`admin/clinical-workers/invitations/${id}`));
}
