import { TFunction } from 'i18next';

import {
    getPatientsDataConfig,
    getPatientDataConfig,
    invitePatientData,
    createPatientInvitationData,
    findPatientByEmailData,
    movePatientData,
    reinvitePatientData,
    getPatientsDeviceStatisticsData,
    getPatientInvitationData,
    updatePatientInvitationData,
    removePatientInvitationData,
} from 'src/web-services/Patients.service';
import { IPatients, PatientSource } from 'src/models/table.models';

import {
    IInvitePatient,
    IMovePatientToClinic,
    IPatientDeviceStatisticsItem,
    IUpsertPatientInvitation,
} from '../../models/app.models';
import {
    createPatientInvitationStructure,
    createPatientDetailedStructure,
    createPatientsFullStructure,
    createPatientsStructure,
    createPatientStructure,
} from '../createPatientsStructure';

export async function findPatientByEmail(email: string, t: TFunction) {
    const res = await findPatientByEmailData(email);

    const { item } = res?.data || { item: null };

    return item != null ? createPatientDetailedStructure(item, t) : null;
}

export async function getPatientsData(t: TFunction) {
    const res = await getPatientsDataConfig();

    const { items, totalCount } = res?.data || { items: null };

    return {
        items: items?.length ? createPatientsStructure(items, t) : [],
        totalCount: totalCount || 0,
    };
}

export async function getPatientData(patientId: string, t: TFunction) {
    const res = await getPatientDataConfig(patientId);

    const { item } = res?.data || { item: null };

    return item != null ? createPatientDetailedStructure(item, t) : null;
}

export async function getPatientInvitation(patientId: string, t: TFunction) {
    const res = await getPatientInvitationData(patientId);

    const { item } = res?.data || { item: null };

    return item != null ? createPatientInvitationStructure(item, t) : null;
}
export async function invitePatient(data: IInvitePatient, t: TFunction) {
    const res = await invitePatientData(data);

    const { item } = res?.data || { item: null };

    return item != null ? createPatientStructure(item, t) : null;
}

export async function createPatientInvitation(data: IUpsertPatientInvitation, t: TFunction) {
    const res = await createPatientInvitationData(data);

    const { item } = res?.data || { item: null };

    return item != null ? createPatientInvitationStructure(item, t) : null;
}

export async function movePatient(patientId: string, data: IMovePatientToClinic, t: TFunction) {
    const res = await movePatientData(patientId, data);
    const { item } = res?.data || { item: null };

    return item != null ? createPatientStructure(item, t) : null;
}

export async function reinvitePatient(data: IInvitePatient, patientId: string, t: TFunction) {
    const res = await reinvitePatientData(data, patientId);

    const { item } = res?.data || { item: null };

    return item != null ? createPatientStructure(item, t) : null;
}

export async function getPatientsWithDeviceStatisticsAndPatientsInvitations(patients: IPatients[]) {
    const patientsIds = patients
        .filter((patient: IPatients) => patient.source === PatientSource.patient)
        .map((patient: IPatients) => patient.id)
        .join(',');

    let patientDeviceStatisticsItems: IPatientDeviceStatisticsItem[] = [];

    if (patientsIds) {
        const res = await getPatientsDeviceStatisticsData(patientsIds);
        patientDeviceStatisticsItems = res?.data?.items ?? [];
    }

    // TODO: Update IListPatientsDataWithDeviceStatistics to avoid type casting
    const patientsWithDeviceStatisticsAndPatientsInvitations = patients.map((patient) => {
        const patientDeviceStatistics = patientDeviceStatisticsItems.find(
            (item) => item.id === patient.id
        ) as IPatientDeviceStatisticsItem;
        return { ...(patientDeviceStatistics && { ...patientDeviceStatistics }), ...patient };
    });

    return createPatientsFullStructure(patientsWithDeviceStatisticsAndPatientsInvitations);
}

export async function updatePatientInvitation(data: IUpsertPatientInvitation, patientId: string, t: TFunction) {
    const res = await updatePatientInvitationData(data, patientId);

    const { item } = res?.data || { item: null };

    return item != null ? createPatientInvitationStructure(item, t) : null;
}

export async function removePatientInvitation(patientId: string) {
    await removePatientInvitationData(patientId);
}
