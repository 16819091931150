export const analyticsEventNames = {
    SET_USER_TYPE: 'set user type',
    REDIRECT_TO_LOGIN: 'redirect to login',
    SUCCESSFUL_LOGIN: 'successful login',
    FAILED_LOGIN: 'failed login',
    LOG_OUT: 'log out',
    REDIRECT_TO_PROVIDERS: 'redirect to providers',
    VIEW_EULA: 'view end user license agreement',
    VIEW_ABOUT_INSULET: 'view about insulet',
    VIEW_CLINIC: 'view clinic',
    VIEW_CONSUMER_HEALTH_DATA_PRIVACY_POLICY: 'view consumer health data privacy policy',
    VIEW_CONTACT_US: 'view contact us',
    VIEW_PRIVACY_POLICY: 'view privacy policy',
    VIEW_COOKIE_POLICY: 'view cookie policy',
    VIEW_CALIFORNIA_HEALTH_AND_SAFETY_CODE: 'view california health and safety code',
    VIEW_CALIFORNIA_SUPPLY_CHAINS_ACT: 'view california supply chains act',
    VIEW_TERMS_OF_USE: 'view terms of use',
    VIEW_END_USER_LICENSE_AGREEMENT: 'view end user license agreement',
    VIEW_IMPORTANT_SAFETY_INFORMATION: 'view important safety information',
    VIEW_HIPAA_PRIVACY_NOTICE: 'view hipaa privacy notice',
    VIEW_INSULET_ALERTS: 'view insulet alerts',
    VIEW_FACEBOOK: 'view facebook',
    VIEW_TWITTER: 'view twitter',
    VIEW_INSTAGRAM: 'view instagram',
    VIEW_YOUTUBE: 'view youtube',
    VIEW_PORTAL_INSTRUCTIONS: 'view portal instructions',
    VIEW_PATIENT_APP_INSTRUCTIONS: 'view patient app instructions',
    VIEW_OMNIPOD_HCP_WEBSITE: 'view omnipod hcp website',
    CLICK_SUPPORT_EMAIL: 'click support email',
    CLICK_VIEW_REPORT: 'click view report',
    CLICK_PRINT_REPORT: 'click print report',
    CLICK_DOWNLOAD_REPORT: 'click download report',
    CLICK_APPROVE_PROVIDERS: 'click approve providers',
    CLICK_DENY_PROVIDERS: 'click deny providers',
    CLICK_VIEW_DASH_EROS_LOCATE_DATA_INSTRUCTIONS: 'click view dash eros locate data instructions',
    CLICK_PRINT_DASH_EROS_LOCATE_DATA_INSTRUCTIONS: 'click print dash eros locate data instructions',
    CHOOSE_PENDING_PROVIDER: 'choose pending provider',
    CHOOSE_APPROVED_PATIENT: 'choose approved patient',
    SELECT_ACTIVE_DAYS: 'select active days',
    CHOOSE_PENDING_PATIENTS: 'choose pending patients',
    REDIRECT_TO_PATIENTS: 'redirect to patients',
    CLICK_APPROVE_PATIENTS: 'click approve patients',
    CLICK_DENY_PATIENTS: 'click deny patients',
    VIEW_PATIENTS: 'view patients',
    VIEW_PROVIDERS: 'view providers',
    VIEW_PENDING_PATIENTS: 'view pending patients',
    VIEW_PENDING_PROVIDERS: 'view pending providers',
    VIEW_HELP: 'view help',
    VIEW_ABOUT: 'view about',
    VIEW_HOME: 'view home',
    VIEW_TRAINING: 'view training',
    VIEW_SUPER_ADMINS: 'view super admins',
    PDM_DEVICE_UPLOADER: 'view pdm device uploader',
    CLICK_UPLOAD_DATA_PATIENT_DIRECTORY: 'click upload data patient directory',
    CLICK_SKIP_DASH_EROS_PDM_DATA_UPLOAD_DATE_AND_TIME_CORRECTION:
        'click skip dash eros pdm data upload date and time correction',
    CLICK_UPDATE_DASH_EROS_PDM_DATA_UPLOAD_DATE_AND_TIME_CORRECTION:
        'click update dash eros pdm data upload date and time correction',
    CLICK_VIEW_EROS_FIND_DATE_AND_TIME_INSTRUCTIONS: 'click view eros find date and time instructions',
    CLICK_VIEW_DASH_FIND_DATE_AND_TIME_INSTRUCTIONS: 'click view dash find date and time instructions',
    VIEW_AGREE_TO_EULA: 'view agree to end user license agreement',
    CHANGE_AGREE_TO_EULA: 'change agree to end user license agreement',
    CLICK_CONTINUE_AGREE_TO_EULA: 'click continue agree to end user license agreement',
    SUCCESS_DASH_EROS_PDM_DATA_UPLOAD: 'success dash eros pdm data upload',
    CLICK_UPLOAD_DATA_DASH_EROS_PDM_DATA_UPLOAD: 'click upload data dash eros pdm data upload',
    FAILURE_FILE_SIGNATURE_ERROR_DASH_EROS_PDM_DATA_UPLOAD: 'failure file signature error dash eros pdm data upload',
    FAILURE_FIRST_IN_SESSION_UNSUCCESSFUL_PROCESSING_OUTCOME_DASH_EROS_PDM_DATA_UPLOAD:
        'failure first in session unsuccessful processing outcome dash eros pdm data upload',
    FAILURE_NOT_FIRST_IN_SESSION_UNSUCCESSFUL_PROCESSING_OUTCOME_DASH_EROS_PDM_DATA_UPLOAD:
        'failure not first in session unsuccessful processing outcome dash eros pdm data upload',
    FAILURE_FILE_TYPE_ERROR_DASH_EROS_PDM_DATA_UPLOAD: 'failure file type error dash eros pdm data upload',
    FAILURE_FILE_TOO_LARGE_ERROR_DASH_EROS_PDM_DATA_UPLOAD: 'failure file too large error dash eros pdm data upload',
    FAILURE_NETWORK_CONNECTION_ERROR_DURING_FILE_UPLOAD_DASH_EROS_PDM_DATA_UPLOAD:
        'failure network connection error during file upload dash eros pdm data upload',
    FAILURE_PDM_CONNECTION_ERROR_DURING_FILE_DOWNLOAD_DASH_EROS_PDM_DATA_UPLOAD:
        'failure pdm connection error during file download dash eros pdm data upload',
};
