import { useSelector } from 'react-redux';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useState } from 'react';

import UserOptionsDropdownMenu from './UserOptionsDropdownMenu';

import { bemBlockName } from '../../pages/Home';
import { RootStateType } from '../../stores/store';
import TruncateText from '../truncateText/TruncateText';

const maximumFirstNameWidth = 180;

const UserOptions = () => {
    const [anchorElement, setAnchorElement] = useState<Element | null>(null);
    const currentUser = useSelector((state: RootStateType) => state.appState.currentUser);

    const handleOnClick = (event: React.MouseEvent<Element>) => {
        setAnchorElement(event.currentTarget);
    };

    // TODO: Currently, we do not handle this kind of accessibility in the app.
    const handleOnKeyDown = () => null;

    const handleOnDropdownMenuClose = () => {
        setAnchorElement(null);
    };

    const isDropdownMenuOpen = !!anchorElement;
    const firstName = currentUser?.name?.first;

    return (
        <>
            <div
                className={`${bemBlockName}__user-options`}
                onClick={handleOnClick}
                onKeyDown={handleOnKeyDown}
                role="button"
                tabIndex={0}
            >
                <AccountCircleOutlinedIcon color="primary" />

                {firstName ? (
                    <span className={`${bemBlockName}__first-name`} data-testid={`${bemBlockName}__first-name`}>
                        <TruncateText text={firstName} maximumTextWidth={maximumFirstNameWidth} />
                    </span>
                ) : null}

                <KeyboardArrowDownOutlinedIcon fontSize="small" />
            </div>

            <UserOptionsDropdownMenu
                anchorElement={anchorElement}
                isOpen={isDropdownMenuOpen}
                onClose={handleOnDropdownMenuClose}
            />
        </>
    );
};

export default UserOptions;
